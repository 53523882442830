// MAIN.JS
jQuery(function($) {

	/**********************************************/



	/**********************************************/

	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
    if ($.fn.mask) {
        $("input[type='tel']").mask("(999) 999-9999? #99999");
        $("input[type='text'].postalcode").mask("a9a 9a9", {
            completed:function () {
                this.val(this.val().toUpperCase());
            }
        });
    }

	/**********************************************/

	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {
		// image
		$('.image-popup').magnificPopup({type:'image'});
		$('.image-group-popup').magnificPopup({
			delegate: 'a',
			type: 'image'
		});
		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
		$('.iframe-group-popup').magnificPopup({
			delegate: 'a',
			type: 'iframe'
		});
	}



	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

	/**********************************************/

	// OWL.CAROUSEL2
	if ($.fn.owlCarousel) {
		// Etre plus specific dans la classe des carousels dans le cas ou nous avons plusieur instance donc les settings differe!
		var owl = $('.owl-carousel');
		owl.owlCarousel ({
		    loop: true,
		    margin: 30,
			autoplay: true,
		    autoplayTimeout: 2000,
		    center: true,
		    slideBy: 2,
		    fluidSpeed: 250,
		    dots: true,
		    responsive:{
		        0:{
		            items:1
		        },
		        480:{
		            items:2
		        },
				768:{
					items:4
				},
		        1000:{
		            items:6
		        }
		    }
		});
		$('.play').on('click', function() {
			owl.trigger('play.owl.autoplay', [1000])
		})
		$('.stop').on('click', function() {
			owl.trigger('stop.owl.autoplay')
		})
	}

	/**********************************************/
	// PARALLAX
	if ($.fn.parallax) {
		$(".parallax-slow").parallax({
			speed: 0.1
		});
		$(".parallax").parallax({
			speed: 0.3
		});
		$(".parallax-fast").parallax({
			speed: 0.5
		});


		// PARALLAX
		function parallax() {
			var scroll = $(window).scrollTop();
			alert(scroll);
		}

		parallax();
		$( window ).scroll(function() {
			parallax();
		});
		$( window ).on('load',function() {
			parallax();
		});
	}

	/************************************************/
	/* menu hide and show when scrolled				*/
	/************************************************/
	let didScroll;
    let lastScrollTop = 0;
    const delta = 10;
    let navbarHeight = $(".menu-desktop").outerHeight();
    // function to store scroll amount in <html data-scroll="">
	const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY;
    }

    // Set flag to see if window has scrolled
	$(window).scroll( function(event) {
		didScroll = true;
        storeScroll();
	});

    // Calls all scroll dependent scripts
    // And cut on processing time by the browser
    // by reading stats only x milliseconds (250)
	setInterval(function() {
		if (didScroll) {
            menuHasScrolled();
            toggleVisibleClassOnScroll();
            didScroll = false;
		}
	}, 250);

	// if element has "visibilityCheck" it will have "isVisible" class when
    // visible
	function toggleVisibleClassOnScroll() {
        $('.visibilityCheck').is( function () {
            let viewing = $(this).visible(true);
            if (viewing) {
                $(this).addClass('isVisible');
            } else {
                $(this).removeClass('isVisible');
            }
        });
        $('.completeVisibilityCheck').is( function () {
            let viewing = $(this).visible();
            if (viewing) {
                $(this).addClass('isCompletelyVisible');
            } else {
                $(this).removeClass('isCompletelyVisible');
            }
        });
    }

    // Adds the nav-invisible and nav-visible to element with class menu-desktop
	function menuHasScrolled() {
        let st = parseInt(document.documentElement.dataset.scroll);

        // Make sure they scroll more than delta
	    if ( Math.abs(lastScrollTop - st) <= delta )
	        return;
	    // If they scrolled down and are past the navbar, add class .nav-invisible.
	    // This is necessary so you never see what is "behind" the navbar.
      //  console.log('st: ' + st);
      //  console.log('lastScrollTop: ' + lastScrollTop);

        if ( st < parseInt(lastScrollTop) ) {
            // Scroll Up
            $('.menu-desktop').addClass('nav-visible').removeClass('nav-invisible');
        }
	    if (st > lastScrollTop && st > navbarHeight) {
	        // Scroll Down
	        $('.menu-desktop').removeClass('nav-visible').addClass('nav-invisible');
	    }

		lastScrollTop = st;

		if (st > 0) {
			$('.module-menu').addClass('scrolled');
		}
		else {
			$('.module-menu').removeClass('scrolled');
		}
	}

	// write the scroll position on page load
    $(document).ready(function () {
        storeScroll();
    });

	/************************************************/
	/* Ferme le popup si cliquer	 				*/
	/************************************************/
	$(".popup").click(function(e) {
	    $(".popup").fadeOut();
	});


	/************************************************/
	/* Montre les messages si présents 				*/
	/************************************************/
	$(document).ready(function () {
        if($('.errors').length) {
            $('html, body').animate({
                scrollTop: $('.errors').offset().top + 200
            }, 'slow');
        }
    });

    $(document).ready(function () {
		if($('.messages').length) {
			$('html, body').animate({
				scrollTop: $('.messages').offset().top + 200
			}, 'slow');
		}
    });


	/************************************************/
	/* Initialise le skrollr si utilisé				*/
	/************************************************/
	if ($.fn.skrollr) {
		skrollr.init({
			smoothScrolling: true,
			smoothScrollingDuration: 500,
			mobileDeceleration: 0.004,
			forceHeight: true,
			render: function(data) {
				//Debugging - Log the current scroll position.
				//console.log(data.curTop);
			}
		});
	}

});
